.bar-chart-resp {
    width: 90%;
    height: 500px;
}

.bar-chart-resp2 {
    width: 60%;
    height: 500px;
}

table td {
    border-top: 0 !important;
}

.section-column {
    width: 22%;
}

.wt-50 {
    width: 50%;
}

.admin-report {
    padding-left: 15px;
    .col{
        white-space: nowrap;
    }
}

@media (min-width: 511px) and (max-width: 920px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (min-width: 210px) and (max-width: 850px) {
    .mobile-column-resp {
        flex-direction: column !important;
    }
}

@media (min-width: 425px) and (max-width: 550px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
    .checkbox-resp {
        flex-direction: column !important;
    }
    .sales-list-search .row {
        margin-bottom: 0 !important;
    }
    .wt-50 {
        width: 100% !important;
    }
}

@media (min-width: 180px) and (max-width: 424px) {
    .bar-chart-resp, .bar-chart-resp2 {
        width: 100% !important;
        height: 100% !important;
    }
    .checkbox-resp {
        flex-direction: row !important;
    }
    .sales-list-search .row {
        margin-bottom: 0 !important;
    }
    .wt-50 {
        width: 100% !important;
        flex-direction: column !important;
    }
    .mobile-column-resp {
        flex-direction: column !important;
    }
}

@media (max-width: 1200px) {
    .section-column {
        width: 28%;
    }
} 

@media (max-width: 992px) {
    .section-column {
        width: 30%;
    }
} 

@media (max-width: 768px) {
    .section-column {
        width: 50%;
    }
} 

@media (max-width: 500px) {
    .section-column {
        width: 100%;
        gap: 5px;
    }
    .mt-3 {
        text-align: left;
    }
} 